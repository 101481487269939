<template>
  <div>
    <b-modal
      :id="'edit-order-' + modalId"
      v-model="modalShow"
      title="Edit Order"
      @show="resetForm"
      @hidden="resetForm"
      size="xl"
      no-close-on-backdrop
      body-class="position-static"
    >
      <!-- This modal has weird loading and reactivity issues, this was the only way I could get a loading spinner to work -->
      <div v-show="isBusy">
        <RbLoadingSpinner variant="success" class="m-auto" />
      </div>

      <div v-show="!isBusy">
        <CustomerInformationForm
          v-if="editCustomerInformation"
          @updateCustomerInformation="updateCustomerInformation"
          :editCustomerInformation="editCustomerInformation"
          :eventId="eventId"
          :currentEventMemberId="currentEventMemberId"
        />
        <OrderDetailsForm
          v-if="editOrderDetails"
          @updateOrderDetails="updateOrderDetails"
          :disableInputs="disableInputs"
          :editOrderDetails="editOrderDetails"
          :required="false"
          action="edit"
          class="mt-4"
        />
        <div class="mt-4">
          <h6 class="font-semibold mb-2">Cart</h6>
          <CreateOrderCart :cartItems="cartItems" :donationAmount="donationAmount" readOnlyMode disabled />
        </div>
      </div>
      <template #modal-footer>
        <div class="flex justify-end w-100">
          <div class="flex">
            <RbButton variant="secondary" :disabled="disabled" @click.native="close()"> Close </RbButton>
            <RbButton
              variant="success"
              :loading="submitting"
              :disabled="disabled"
              class="ml-2"
              @click.native="onSubmit()"
            >
              Save
            </RbButton>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import OrderServiceV2 from '../lib/order-service-v2';
import OrderDetailsForm from '@/components/forms/OrderDetailsForm';
import CustomerInformationForm from '@/components/forms/CustomerInformationForm';
import CreateOrderCart from '@/components/CreateOrderCart';
import { mapOrderTypeToLabel } from '@/lib/order-types';
import { zonedTimeToUtc } from 'date-fns-tz';
import { parseISO, set } from 'date-fns';

const orderSource = [
  { value: null, text: '--- Select a source ---' },
  { value: 'mail', text: 'Mail' },
  { value: 'phone', text: 'Phone' },
  { value: 'walk_in', text: 'Walk in' }
];

export default {
  components: {
    OrderDetailsForm,
    CustomerInformationForm,
    CreateOrderCart
  },
  props: {
    modalId: String,
    orderId: String
  },
  data() {
    return {
      disabled: false,
      orderGroupId: null,
      modalShow: false,
      errorMessage: null,
      email: null,
      phone: null,
      firstName: null,
      lastName: null,
      secondaryName: null,
      address: null,
      province: null,
      provinceStateOptions: {},
      provinceStateView: {},
      postal: null,
      city: null,
      eventId: null,
      country: null,
      comment: null,
      currentEventMemberId: null,
      optionsOrderSource: orderSource,
      isBusy: true,
      submitting: false,
      orderStatus: null,
      editOrderDetails: {
        orderType: null,
        orderStatus: null,
        orderSource: null,
        orderReceivedDate: null,
        batchId: null,
        referenceId: null
      },
      editCustomerInformation: {
        firstName: null,
        lastName: null,
        title: null,
        age: null,
        address: null,
        city: null,
        province: null,
        postalCode: null,
        email: null,
        phone: null,
        additionalNames: null,
        comment: null,
        shippingAddressLine1: null,
        shippingCity: null,
        shippingState: null,
        shippingPostal: null
      },
      cartItems: [],
      donationAmount: 0
    };
  },
  computed: {
    disableInputs() {
      return this.orderStatus !== 'PENDING';
    }
  },
  watch: {
    modalShow: async function () {
      await this.getOrder();
      this.isBusy = false;
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    resetForm() {
      this.isBusy = true;
      this.errorMessage = null;
      this.eventId = null;
      this.country = null;
      this.currentEventMemberId = null;

      this.editOrderDetails.orderType = null;
      this.editOrderDetails.orderStatus = null;
      this.editOrderDetails.orderSource = null;
      this.editOrderDetails.orderReceivedDate = null;
      this.editOrderDetails.batchId = null;
      this.editOrderDetails.referenceId = null;

      this.editCustomerInformation.firstName = null;
      this.editCustomerInformation.lastName = null;
      this.editCustomerInformation.title = null;
      this.editCustomerInformation.age = null;
      this.editCustomerInformation.address = null;
      this.editCustomerInformation.city = null;
      this.editCustomerInformation.province = null;
      this.editCustomerInformation.postalCode = null;
      this.editCustomerInformation.email = null;
      this.editCustomerInformation.phone = null;
      this.editCustomerInformation.additionalNames = null;
      this.editCustomerInformation.comment = null;
      this.editCustomerInformation.shippingAddressLine1 = null;
      this.editCustomerInformation.shippingCity = null;
      this.editCustomerInformation.shippingState = null;
      this.editCustomerInformation.shippingPostal = null;
      this.editCustomerInformation.shippingEnabled = false;
      this.cartItems = [];
      this.donationAmount = 0;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    async getOrder() {
      try {
        const order = await OrderServiceV2.retrieveOrder(this.orderId);
        await this.getOrderGroup(order);

        this.orderId = order.id;
        this.eventId = order.eventId;

        this.orderGroupId = order.orderGroupId;

        this.country = order.country;
        this.comment = order.info?.comment;
        this.currentEventMemberId = order.eventMemberId || null;
        this.orderStatus = order.status;
        this.provinceStateOptions = this.provinceStateView.dropdownOptions;

        this.editOrderDetails.orderType = mapOrderTypeToLabel(order.type);
        this.editOrderDetails.orderStatus = order.status.toLowerCase();

        this.editOrderDetails.orderReceivedDate = order.receivedDate;
        this.editOrderDetails.batchId = order.batchId;
        this.editOrderDetails.orderSource = order.info.source;
        this.editOrderDetails.referenceId = order.referenceId;

        this.editCustomerInformation.firstName = order.firstName;
        this.editCustomerInformation.lastName = order.lastName;
        this.editCustomerInformation.age = order.age;
        this.editCustomerInformation.title = order.title;
        this.editCustomerInformation.address = order.address;
        this.editCustomerInformation.city = order.city;
        this.editCustomerInformation.province = order.province;
        this.editCustomerInformation.postalCode = order.postal;
        this.editCustomerInformation.email = order.email;
        this.editCustomerInformation.phone = order.phone;
        this.editCustomerInformation.additionalNames = order.secondaryName;
        this.editCustomerInformation.comment = order.info?.comment;
        this.editCustomerInformation.eventMemberId = order.eventMemberId;
        this.editCustomerInformation.shippingAddressLine1 = order.shippingAddressLine1;
        this.editCustomerInformation.shippingCity = order.shippingCity;
        this.editCustomerInformation.shippingState = order.shippingState;
        this.editCustomerInformation.shippingPostal = order.shippingPostal;
        this.editCustomerInformation.shippingEnabled = order.shippingEnabled;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },

    async getOrderGroup(order) {
      const result = await OrderServiceV2.getOrderGroup(order.orderGroupId);

      if (result.cartItems) {
        for (const item of result.cartItems) {
          if (item.amountCents) {
            // Capture donation
            this.donationAmount = item.amountCents / 100;
          } else {
            const { ticketSeries } = item.ticketPricing;
            const ticketPackages = ticketSeries.map((ticketPackage) => {
              return {
                quantity: ticketPackage.groups,
                numTickets: ticketPackage.numTickets,
                price: ticketPackage.price
              };
            });

            const cartItem = {
              event: item.event,
              ticketPackages: ticketPackages
            };
            this.cartItems.push(cartItem);
          }
        }
      }
    },

    async onSubmit() {
      try {
        this.submitting = true;
        this.errorMessage = null;

        let dateTime;

        if (this.orderDetails.orderReceivedDate) {
          if (this.orderDetails.orderReceivedDate.includes('T')) {
            dateTime = this.orderDetails.orderReceivedDate;
          } else {
            const date = set(parseISO(this.orderDetails.orderReceivedDate), {
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0
            });

            const utcToZonedTime = zonedTimeToUtc(date, this.$store.getters.getOrganization.timeZone).toISOString();

            dateTime = utcToZonedTime;
          }
        }

        const orderUpdateData = {
          // This will allow empty srtings to be sent to the API for the email and postal fields
          // All other purchaser fields will be not be submitted if they are blank and therefore will not update
          firstName: this.isStringEmpty(this.editCustomerInformation.firstName),
          lastName: this.isStringEmpty(this.editCustomerInformation.lastName),
          secondaryName: this.isStringEmpty(this.editCustomerInformation.additionalNames),
          email: this.isStringEmpty(this.editCustomerInformation.email),
          phone: this.isStringEmpty(this.editCustomerInformation.phone),
          address: this.isStringEmpty(this.editCustomerInformation.address),
          city: this.isStringEmpty(this.editCustomerInformation.city),
          state: this.editCustomerInformation.province ? this.editCustomerInformation.province : '',
          postal: this.editCustomerInformation.postalCode
            ? this.editCustomerInformation.postalCode?.replace(/\s/g, '')
            : '',
          title: this.isStringEmpty(this.editCustomerInformation.title),
          age: this.isStringEmpty(this.editCustomerInformation.age),
          comment: this.isStringEmpty(this.editCustomerInformation.comment),
          customerId: this.currentCustomerId,
          source: this.orderDetails.orderSource,
          batchId: this.isStringEmpty(this.orderDetails.batchId),
          referenceId: this.orderDetails.referenceId ? this.orderDetails.referenceId : '',
          receivedDate: dateTime ?? undefined,
          shippingEnabled: this.editCustomerInformation.shippingEnabled,
          orderType: this.orderDetails.orderType
        };

        if (!this.editCustomerInformation.shippingEnabled) {
          orderUpdateData.shippingAddressLine1 = this.isStringEmpty(this.editCustomerInformation.shippingAddressLine1);
          orderUpdateData.shippingCity = this.isStringEmpty(this.editCustomerInformation.shippingCity);
          orderUpdateData.shippingState = this.editCustomerInformation.shippingState
            ? this.editCustomerInformation.shippingState
            : '';
          orderUpdateData.shippingPostal = this.editCustomerInformation.shippingPostal
            ? this.editCustomerInformation.shippingPostal?.replace(/\s/g, '')
            : '';
        } else {
          orderUpdateData.shippingAddressLine1 = this.isStringEmpty(this.editCustomerInformation.address);
          orderUpdateData.shippingCity = this.isStringEmpty(this.editCustomerInformation.city);
          orderUpdateData.shippingState = this.editCustomerInformation.province
            ? this.editCustomerInformation.province
            : '';
          orderUpdateData.shippingPostal = this.editCustomerInformation.postalCode
            ? this.editCustomerInformation.postalCode.replace(/\s/g, '')
            : '';
        }

        if (this.orderDetails.orderStatus !== 'void') {
          orderUpdateData.status = this.orderDetails.orderStatus.toUpperCase() ?? null;
        }

        await OrderServiceV2.updateOrderGroup(this.orderGroupId, orderUpdateData);
        this.$emit('orderUpdated');
        this.modalShow = false;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.submitting = false;
      }
    },
    updateOrderDetails(orderDetails) {
      this.orderDetails = orderDetails;
    },
    updateCustomerInformation(customerInformation) {
      this.editCustomerInformation = customerInformation;
    },
    close() {
      this.resetForm();
      this.$bvModal.hide('edit-order-' + this.modalId);
    }
  }
};
</script>
