<template>
  <div>
    <h6 class="font-semibold mb-2">Order Details</h6>
    <!-- First Row -->
    <div class="flex gap-4">
      <b-form-group label-for="order-type" :invalid-feedback="veeErrors.first('order-type')" class="flex-1">
        <template v-slot:label>Order Type <span class="text-danger" v-if="required">*</span> </template>
        <b-form-select
          name="order-type"
          v-model="orderDetails.orderType"
          :options="orderTypeOptions"
          v-validate="{ required: required }"
          :state="validateState('order-type')"
          data-vv-as="order type"
          :disabled="disableInputs"
        ></b-form-select>
      </b-form-group>

      <b-form-group label-for="order-source" :invalid-feedback="veeErrors.first('order-source')" class="flex-1">
        <template v-slot:label>Order Source</template>
        <b-form-select
          name="order-source"
          v-model="orderDetails.orderSource"
          :options="orderSourceOptions"
          :state="validateState('order-source')"
          data-vv-as="order source"
          :disabled="disableInputs"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        label-for="order-received-date"
        :invalid-feedback="veeErrors.first('order-received-date')"
        class="flex-1"
      >
        <template v-slot:label>Order Received Date</template>
        <b-form-datepicker
          name="order-received-date"
          v-model="orderDetails.orderReceivedDate"
          :state="validateState('order-received-date')"
          data-vv-as="order received date"
          :disabled="disableInputs"
        ></b-form-datepicker>
      </b-form-group>
    </div>

    <!-- Second Row -->
    <div class="flex gap-4">
      <b-form-group label-for="order-status" :invalid-feedback="veeErrors.first('order-status')" class="flex-1">
        <template v-slot:label>Order Status <span class="text-danger">*</span> </template>
        <b-form-select
          name="order-status"
          v-model="orderDetails.orderStatus"
          :options="orderStatusOptions"
          v-validate="{ required: true }"
          :state="validateState('order-status')"
          data-vv-as="order status"
          :disabled="disableInputs"
        ></b-form-select>
        <small v-if="showHelperMessage" class="ml-2 text-gray-600">{{ helperMessage }}</small>
      </b-form-group>

      <b-form-group
        label-for="order-reference-id"
        :invalid-feedback="veeErrors.first('order-reference-id')"
        class="flex-1"
      >
        <template v-slot:label>
          <div class="flex">
            <p class="mb-0">Reference ID</p>
            <span
              class="ml-1"
              v-b-tooltip.hover.top="
                'This can be a cheque number, third party payment provider ID, or any other identifier you wish to use for your records.'
              "
            >
              <span class="fas fa-info-circle"></span>
            </span>
          </div>
        </template>
        <b-form-input
          name="order-reference-id"
          v-model="orderDetails.referenceId"
          v-validate="{ max: 255 }"
          :state="validateState('order-reference-id')"
          data-vv-as="order reference id"
          :disabled="disableInputs"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-for="order-batch-id" :invalid-feedback="veeErrors.first('order-batch-id')" class="flex-1">
        <template v-slot:label>
          <div class="flex">
            <p class="mb-0">Batch ID</p>
            <span
              class="ml-1"
              v-b-tooltip.hover.top="
                'An identification number used to help group orders into batches. Orders can later be processed by batch using this ID.'
              "
            >
              <span class="fas fa-info-circle"></span>
            </span>
          </div>
        </template>
        <b-form-input
          name="order-batch-id"
          v-model="orderDetails.batchId"
          v-validate="{ max: 255 }"
          :state="validateState('order-batch-id')"
          data-vv-as="order batch id"
          :disabled="disableInputs"
        ></b-form-input>
      </b-form-group>
    </div>
  </div>
</template>

<script>
const orderTypes = [
  { value: null, text: '--- Select a type ---' },
  { value: 'cash', text: 'Cash' },
  { value: 'cheque', text: 'Cheque' },
  { value: 'money order', text: 'Money Order' }
];

const orderSources = [
  { value: null, text: '--- Select a source ---' },
  { value: 'mail', text: 'Mail' },
  { value: 'phone', text: 'Phone' },
  { value: 'walk_in', text: 'Walk in' }
];

export default {
  props: {
    disableInputs: {
      type: Boolean,
      default: false
    },
    editOrderDetails: {
      type: Object,
      required: false
    },
    required: {
      type: Boolean,
      default: true
    },
    action: {
      type: String,
      required: true,
      validator: (value) => ['create', 'edit'].includes(value)
    }
  },
  data() {
    return {
      orderTypeOptions: orderTypes,
      orderSourceOptions: orderSources,
      orderDetails: {
        orderType: null,
        orderSource: null,
        orderReceivedDate: null,
        orderStatus: null,
        referenceId: null,
        batchId: null
      },
      showHelperMessage: false,
      helperMessage: null
    };
  },
  computed: {
    orderStatusOptions() {
      const orderStatuses = [
        { value: null, text: '--- Select a status ---' },
        { value: 'pending', text: 'Pending' },
        { value: 'active', text: 'Active' }
      ];

      if (this.action === 'edit') {
        orderStatuses.push({ value: 'failed', text: 'Failed' });
      }

      return orderStatuses;
    }
  },
  watch: {
    orderDetails: {
      handler() {
        this.$emit('updateOrderDetails', this.orderDetails);
      },
      deep: true
    },
    editOrderDetails: {
      handler() {
        if (this.editOrderDetails !== this.orderDetails) {
          this.orderDetails = { ...this.editOrderDetails };
        }
      },
      deep: true
    },
    'orderDetails.orderStatus': {
      handler(newVal, oldVal) {
        if (oldVal === 'pending' && newVal === 'active') {
          this.showHelperMessage = true;
          this.helperMessage = `Order status change to 'Active' issues tickets permanently.`;
        } else {
          this.showHelperMessage = false;
          this.helperMessage = null;
        }
      }
    }
  },
  mounted() {
    if (this.editOrderDetails) {
      this.orderDetails = { ...this.editOrderDetails };
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>
